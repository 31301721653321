<template>
  <v-container fluid>
    <div class="main">
      <v-row>
        <v-col cols="3">
          <div class="sticky-block">
            <v-expansion-panels
              multiple
              v-model="panel"
              flat
              class="rounded-lg"
            >
              <v-expansion-panel key="0">
                <v-expansion-panel-header>
                  <div>
                    <v-icon left>mdi-filter-outline</v-icon>
                    Filtrage
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <form @submit.prevent="[fetchData()]">
                    <v-row>
                      <v-dialog
                        ref="dialogStartDate"
                        v-model="modalStartDate"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formFilter.startDate"
                            label="Date début"
                            hide-details
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="formFilter.startDate"
                          @input="
                            [
                              (formFilter.endDate = null),
                              (modalStartDate = false),
                            ]
                          "
                        >
                        </v-date-picker>
                      </v-dialog>
                    </v-row>
                    <v-row>
                      <v-dialog
                        ref="dialogEndDate"
                        v-model="modalEndDate"
                        @click="modalEndDate = false"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formFilter.endDate"
                            label="Date fin"
                            hide-details
                            :disabled="!formFilter.startDate"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @input="modalEndDate = false"
                          v-model="formFilter.endDate"
                          :min="formFilter.startDate"
                        >
                        </v-date-picker>
                      </v-dialog>
                    </v-row>

                    <v-row>
                      <label class="mt-5">Statut :</label>
                    </v-row>
                    <v-row>
                      <v-chip-group v-model="formFilter.status" column>
                        <v-chip filter color="blue" outlined value="all"
                          >Tous</v-chip
                        >
                        <v-chip filter color="warning" outlined value="pending"
                          >En attente</v-chip
                        >
                        <v-chip
                          filter
                          color="success "
                          outlined
                          value="accepted"
                          >Acceptée</v-chip
                        >
                        <v-chip filter color="error" outlined value="refused"
                          >Réfusée</v-chip
                        >
                      </v-chip-group>
                    </v-row>
                    <v-btn
                      type="submit"
                      color="secondary lighten-1 mt-4"
                      large
                      depressed
                      class="rounded-lg text-none"
                    >
                      <v-icon left>mdi-filter-outline</v-icon>
                      Filtrer
                    </v-btn>
                  </form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>

        <v-col cols="9">
          <v-card class="rounded-lg" flat>
            <v-card-text>
              <div class="d-flex justify-space-between mb-2">
                <div></div>
                <div>
                  <v-text-field
                    v-model="keyword"
                    rounded
                    filled
                    dense
                    style="width: 300px"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                    clearable
                    placeholder="Rechercher..."
                  />
                </div>
              </div>
              <v-divider />
              <div v-if="isLoading">
                <v-skeleton-loader type="table"></v-skeleton-loader>
              </div>
              <div v-else>
                <div v-if="voucherOrders.data.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="font-weight-bold text-no-wrap">
                            ID
                          </th>
                          <th class="font-weight-bold text-no-wrap">
                            Fournisseur
                          </th>
                          <th class="font-weight-bold text-no-wrap">
                            Demandeur
                          </th>
                          <th class="font-weight-bold text-no-wrap">Montant</th>
                          <th class="font-weight-bold text-no-wrap">Etat</th>
                          <th class="font-weight-bold text-no-wrap">date</th>
                          <th class="font-weight-bold text-no-wrap">Service</th>
                          <th class="font-weight-bold text-no-wrap">Numero</th>
                          <th class="font-weight-bold text-no-wrap">Action</th>
                          <th class="font-weight-bold text-no-wrap"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in voucherOrders.data" :key="item.id">
                          <td class="font-weight-bold text-no-wrap">
                            {{ item.id }}
                          </td>
                          <td class="font-weight-bold text-no-wrap">
                            <div class="d-flex">
                              <v-avatar size="35">
                                <v-img :src="require('@/assets/avatar.png')" />
                              </v-avatar>
                              <span class="pt-2 ml-1">
                                {{ item.supplier ? item.supplier.name : "-" }}
                              </span>
                            </div>
                          </td>
                          <td class="font-weight-bold text-no-wrap">
                            {{ item.user.name }}
                          </td>
                          <td
                            class="font-weight-bold text-no-wrap pink--text text-right"
                          >
                            {{ CurrencyFormatting(item.amount) }} DZD
                          </td>
                          <td class="font-weight-bold text-no-wrap">
                            <v-chip
                              :color="setStatusColor(item.state)"
                              label
                              small
                              dark
                            >
                              {{ setStatus(item.state) }}
                            </v-chip>
                          </td>

                          <td class="font-weight-bold text-no-wrap">
                            {{ item.created_at }}
                          </td>
                          <td class="font-weight-bold text-no-wrap">
                            {{ item.service ? item.service.name : "-" }}
                          </td>
                          <td class="font-weight-bold text-no-wrap">
                            {{ item.user.phone }}
                          </td>

                          <td>
                            <v-btn
                              color="primary"
                              elevation="2"
                              v-if="item.state == 'pending'"
                              class="text-none rounded-lg ml-2"
                              @click="
                                [
                                  (updateNameDialog = true),
                                  (sendCodeForm.id = item.id),
                                ]
                              "
                            >
                              <v-icon left>mdi-check-circle</v-icon>
                              Envoyer le bon
                            </v-btn>
                          </td>
                          <td>
                            <v-btn
                              color="error"
                              elevation="0"
                              v-if="item.state == 'pending'"
                              class="text-none rounded-lg ml-2"
                              :loading="cancelLoading"
                              @click="cancelOrder(item)"
                            >
                              <v-icon left>mdi-close</v-icon>
                              Annuler
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div class="d-flex mt-4">
                    <span class="mt-2 mr-2">Lignes par page :</span>
                    <v-select
                      filled
                      v-model="filter.size"
                      style="width: 20px"
                      dense
                      :items="[10, 20, 30, 40, 50]"
                      @change="onPageChange"
                    ></v-select>
                    <v-spacer />
                    <v-pagination
                      total-visible="6"
                      v-if="voucherOrders.total > 15"
                      circle
                      v-model="filter.page"
                      :length="Math.ceil(voucherOrders.total / filter.size)"
                      @input="onPageChange"
                    ></v-pagination>
                  </div>
                </div>
                <div v-else class="text-center">
                  <v-avatar tile size="200">
                    <v-img :src="require('@/assets/database.svg')"></v-img>
                  </v-avatar>
                  <h3 class="text--primary d-block mb-2">OOPS !</h3>
                  <p>Aucun enregistrement correspondant trouvé.</p>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="updateNameDialog" width="500" persistent>
      <form @submit.prevent="sendCode">
        <v-card>
          <v-card-title>
            Envoie du bon d'achat
            <v-spacer />
            <v-btn
              icon
              class="v-btn--active"
              color="primary"
              @click="updateNameDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-text-field
              label="Code "
              @input="
                sendCodeErrors && sendCodeErrors.code
                  ? (sendCodeErrors.code = '')
                  : false
              "
              :error-messages="sendCodeErrors.code"
              v-model="sendCodeForm.code"
            />

            <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="sendCodeForm.expiration_date"
                      label="Date d'expiration *"
                      prepend-inner-icon="mdi-calendar-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                    
                    
                  </template>
                  <v-date-picker
                    v-model="sendCodeForm.expiration_date"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
                <p
                      v-if="sendCodeErrors && sendCodeErrors.expiration_date"
                      class="red--text mt-0 text--danger"
                    >
                      {{ sendCodeErrors.expiration_date[0] }}
                    </p>

            <v-btn
              color="primary"
              :loading="btnUpdateLoading"
              depressed
              class="rounded-lg text-none mt-3"
              large
              type="submit"
            >
              <v-icon left>mdi-send</v-icon>
              Envoyer
            </v-btn>
          </v-card-text>
        </v-card>
      </form>
    </v-dialog>

    <ConfirmCancelOrder ref="cancelOrder" @refresh="fetchData()"/>
  </v-container>
</template>

<script>
import { HTTP } from "@/http-common";

import ConfirmCancelOrder from "./ConfirmCancelOrder.vue";
export default {
  components: { ConfirmCancelOrder },
  data() {
    return {
      dateMenu: false,
      // database: {},
      set_paginate: 10,
      keyword: "",
      panel: [0],
      dialogSms: false,
      uploadDialog: false,
      updateNameDialog: false,
      btnUpdateLoading: false,
      uploadErrors: [],
      pagination: {
        current: 1,
        total: 0,
      },
      uploadForm: {
        excel_file: "",
        amount: "",
      },

      sendCodeForm: {
        id: 0,
        code: 0,
        expiration_date: ""
      },

      formFilter: {
        status: "pending",
      },
      sendCodeErrors: {},
      btnUploadLoading: false,
      ischeckLoading: false,
      modalStartDate: false,
      modalEndDate: false,

      cancelLoading: false,

      filter: {
        salepoint_type_ids: [],

        selectedCommunes: [],
        selectedWilayas: [],
        has_gifty: "all",
        period: [],
        startDate: "",
        endDate: "",
        keyword: "",
        size: 10,
        page: 1,
      },
    };
  },
  methods: {
    fetchData() {
      this.filter.keyword = this.keyword;
      this.filter.startDate = this.formFilter.startDate;
      this.filter.endDate = this.formFilter.endDate;
      this.filter.status = this.formFilter.status;
      this.$store.dispatch("fetchVoucherOrders", {
        filter: this.filter,
      });
    },
    setStatusColor(status) {
      switch (status) {
        case "pending":
          return "warning ";
        case "refused":
          return "error";
        case "accepted":
          return "success ";
      }
    },
    setStatus(status) {
      switch (status) {
        case "pending":
          return "En attente";
        case "refused":
          return "Refusée";
        case "accepted":
          return "Acceptée";
      }
    },

    onPageChange() {
      this.fetchData();
    },

    filterRegion() {
      var hash_array = [];

      var numeric_array = [];
      for (var item in this.filter.selectedWilayas) {
        numeric_array.push(this.filter.selectedWilayas[item]);
      }
      console.log(numeric_array);
      this.$store.dispatch("fetchCommunes", {
        filter: numeric_array,
      });
    },
    resetFilter() {
      this.keyword = "";

      this.filter = {
        selectedCommunes: [],
        selectedWilayas: [],
        has_gifty: "all",
        period: [],
        startDate: "",
        endDate: "",
        keyword: "",
        size: 10,
      };
    },
    sendCode() {
      this.btnUpdateLoading = true;
      let formData = new FormData();

      formData.append("code", this.sendCodeForm.code);
      formData.append("expiration_date", this.sendCodeForm.expiration_date);
      HTTP.post("/voucher/order/send/" + this.sendCodeForm.id, formData)
        .then(() => {
          this.$successMessage = "Le bon d'achat a été envoyer ";
          this.btnUpdateLoading = false;
          this.sendCodeForm = {};

          this.updateNameDialog = false;
          this.fetchData();
        })
        .catch((err) => {
          this.btnUpdateLoading = false;
          if(err.response && err.response.data && err.response.data.errors){
            
            this.sendCodeErrors = err.response.data.errors;

            console.log( this.sendCodeErrors );
          }
          
          console.log(err);
        });
    },

    cancelOrder(order) {
      this.$refs.cancelOrder.open(order);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading;
    },

    salepoints() {
      return this.$store.getters.getSalepoints;
    },
    wilayas() {
      return this.$store.getters.getWilayas;
    },

    communes() {
      return this.$store.getters.getCommunes;
    },

    database() {
      return this.$store.getters.getDatabase;
    },

    voucherOrders() {
      return this.$store.getters.getVoucherOrders;
    },

    user() {
      return this.$store.getters.getUser;
    },
    messages() {
      return 0;
    },
  },
  watch: {
    keyword() {
      this.fetchData();
    },
  },

  mounted() {
    this.fetchData();
    if (this.wilayas.length == 0) this.$store.dispatch("fetchwilayas");
  },
};
</script>
